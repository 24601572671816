import {
  faGithub,
  faLinkedin,
  faStackOverflow,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

const SocialIcons = () => {
  return (
    <div className="icons-social">
      <a
        title="email"
        className="hvr-grow"
        rel="noopener noreferrer"
        href="mailto:hello@joni.dev"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        title="Github"
        className="hvr-grow"
        rel="noopener noreferrer"
        href="https://github.com/JoniVR"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        title="LinkedIn"
        className="hvr-grow"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/JoniVR"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        title="Twitter"
        className="hvr-grow"
        rel="noopener noreferrer"
        href="https://twitter.com/JoniVR"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        title="Stackoverflow"
        className="hvr-grow"
        rel="noopener noreferrer"
        href="https://stackoverflow.com/users/6863743/jonivr"
      >
        <FontAwesomeIcon icon={faStackOverflow} />
      </a>
    </div>
  );
};

export default SocialIcons;
