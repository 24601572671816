import "./style.scss";

import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import { toTop as scrollToTop } from "../../utils/scroll";

const ScrollTop = () => {
  const [showScrollTop, setShowScrollTop] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const boundingRect = (
      (document || {}).documentElement || {}
    ).getBoundingClientRect();
    if (boundingRect) {
      if (document.documentElement.getBoundingClientRect().top * -1 > 100)
        setShowScrollTop(true);
      else setShowScrollTop(false);
    }
  };

  return (
    <div className="scroll-top" onClick={() => scrollToTop()}>
      <div className={`arrow ${showScrollTop ? "" : "hide"}`}>
        <FontAwesomeIcon icon={faAngleDoubleUp} size="2x" />
        <div className="to-top">To Top</div>
      </div>
    </div>
  );
};

export default ScrollTop;
