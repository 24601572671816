import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "../../hooks/useTheme";
import { toTitleCase } from "../../utils/TextUtils";
import "./style.scss";

const Nav = () => {
  const { isDark, switchTheme, theme } = useTheme();
  return (
    <nav>
      <div className="themebtn-wrapper" onClick={switchTheme}>
        <FontAwesomeIcon
          className="themebtn"
          icon={isDark() ? faMoon : faSun}
        />
        <div className="theme-text">Theme: {toTitleCase(theme)}</div>
      </div>
      <div className="menu">
        <a className="navlink hover-link-secondary" href="#about">
          About
        </a>
        <a className="navlink hover-link-secondary" href="#projects">
          Projects
        </a>
      </div>
    </nav>
  );
};

export default Nav;
