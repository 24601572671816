import Nav from "./components/Nav/Nav";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import AboutPage from "./pages/AboutPage/AboutPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import ParticlesBackground from "./components/ParticlesBackground/ParticlesBackground";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ThemeProvider from "./providers/ThemeProvider";

const App = () => {
  return (
    <ThemeProvider>
      <Nav />
      <LandingPage />
      <AboutPage />
      <ProjectPage />
      <ScrollTop />
      <ParticlesBackground />
    </ThemeProvider>
  );
};

export default App;
