import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

interface ScrollNextProps {
  pageSelector: string;
}

const ScrollNext = (props: ScrollNextProps) => (
  <div className="scroll-to-next">
    <div className="arrow bounce">
      <div className="scroll-text">Scroll Next</div>
      <a href={props.pageSelector} title="Scroll next">
        <FontAwesomeIcon icon={faChevronDown} size="2x" />
      </a>
    </div>
  </div>
);

export default ScrollNext;
