import ScrollNext from "../../components/ScrollNext/ScrollNext";
import "./style.scss";

const AboutPage = () => (
  <div className="layout-card" id="about">
    <div className="about">
      <div className="content-grid">
        <h1>About</h1>
        <div className="about-content">
          <p>
            My name is <span className="highlight">Joni Van Roost</span>.
          </p>
          <p>
            I'm a full stack{" "}
            <span className="highlight">software engineer</span> who loves{" "}
            <span className="highlight">
              <a href="https://github.com/jonivr">Open Source</a>.
            </span>{" "}
            I care about great user experiences and primarily focus on mobile
            and web.
          </p>
          <dl>
            <dt>
              <span className="highlight">Programming languages</span>
            </dt>
            <dd>Swift, Java, C#, Scala, Bash, HTML & CSS, JS, TS</dd>
            <dt>
              <span className="highlight">Frameworks</span>
            </dt>
            <dd>
              React (Native), Angular, Spring Boot, .NET, Cocoa (macOS/iOS),
              Android, NodeJS, Electron, Unity
            </dd>
            <dt>
              <span className="highlight">Technologies</span>
            </dt>
            <dd>Git, NoSQL, SQL, REST, Websockets, Linux Operating System</dd>
          </dl>
        </div>
      </div>
    </div>
    <ScrollNext pageSelector="#projects" />
  </div>
);

export default AboutPage;
