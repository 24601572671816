import { AbsoluteTheme } from '../model/AbsoluteTheme';
import { Theme } from '../model/Theme';

export const readTheme = (): Theme => {
  let theme = localStorage.getItem('theme') as Theme | null;
  if (theme == null) {
    theme = 'auto';
  }
  return theme;
};

export const storeTheme = (theme: Theme) => {
  localStorage.setItem('theme', theme);
};

/**
 * Reads the auto theme, this can either be light or dark, but never auto.
 */
export const readautoAbsoluteTheme = (): AbsoluteTheme => {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return isDark ? 'dark' : 'light';
};

export const asAbsoluteTheme = (theme: Theme): AbsoluteTheme => {
  if (theme === 'auto') {
    return readautoAbsoluteTheme();
  }
  return theme;
};

/**
 * Returns the next logical theme to set.
 * @param theme The current theme.
 */
export const getNextTheme = (theme: Theme): Theme => {
  let themes = ['auto', 'light', 'dark'];
  // if theme is auto and auto theme is light, change order
  // this is to ensure we always switch value on first click.
  if (theme === 'auto' && readautoAbsoluteTheme() === 'light') {
    themes = ['auto', 'dark', 'light'];
  }
  let activeIndex = themes.indexOf(theme);
  if (++activeIndex < themes.length) {
    return themes[activeIndex] as Theme;
  } else {
    return themes[0] as Theme;
  }
};
