import {
  faApple,
  faAppStore,
  faChrome,
  faGooglePlay,
  faJsSquare,
  faReact,
  faSwift,
} from "@fortawesome/free-brands-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProjectItems = [
  {
    name: "EmergencyMobile",
    render: (
      <div className="project-item">
        <div className="project-item__title">FrontForce Emergency Mobile</div>
        <div className="project-item__desc">
          <span>
            App for alerting, informing and scheduling firefighters from{" "}
            <a
              href="https://www.frontforce.be/"
              className="hover-link-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              FrontForce
            </a>
            .
          </span>
        </div>
        <div className="project-item__icon" title="React Native">
          <FontAwesomeIcon icon={faReact} />
        </div>
        <div className="project-item__links">
          <a
            title="App Store"
            className="hover-link-primary hover-icon"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/be/app/frontforce-emergency/id1534696492#?platform=iphone"
          >
            <FontAwesomeIcon
              icon={faAppStore}
              style={{ width: 22, height: 22 }}
            />
          </a>
          <a
            title="Play Store"
            className="hover-link-primary hover-icon"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=be.frontforce.emergency.mobile"
          >
            <FontAwesomeIcon
              icon={faGooglePlay}
              style={{ width: 22, height: 22 }}
            />
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "InTheField",
    render: (
      <div className="project-item">
        <div className="project-item__title">FrontForce In The Field</div>
        <div className="project-item__desc">
          <span>
            Field support app for first responders from{" "}
            <a
              href="https://www.frontforce.be/"
              className="hover-link-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              FrontForce
            </a>
            .
          </span>
        </div>
        <div className="project-item__icon" title="React Native">
          <FontAwesomeIcon icon={faReact} />
        </div>
        <div className="project-item__links">
          <a
            title="App Store"
            className="hover-link-primary hover-icon"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/be/app/frontforce-in-the-field/id1591587498"
          >
            <FontAwesomeIcon
              icon={faAppStore}
              style={{ width: 22, height: 22 }}
            />
          </a>
          <a
            title="Play Store"
            className="hover-link-primary hover-icon"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=be.frontforce.emergency.inthefield"
          >
            <FontAwesomeIcon
              icon={faGooglePlay}
              style={{ width: 22, height: 22 }}
            />
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "vcs",
    render: (
      <div className="project-item">
        <div className="project-item__title">VerticalCardSwiper</div>
        <div className="project-item__desc">
          A marriage between the Shazam Discover UI and Tinder, built with
          UICollectionView in Swift.
        </div>
        <div className="project-item__icon">
          <FontAwesomeIcon icon={faSwift} title="Swift" />
          <FontAwesomeIcon icon={faApple} title="Apple" />
        </div>
        <div className="project-item__links">
          <a
            className="hover-link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/JoniVR/VerticalCardSwiper"
          >
            Visit
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "monitorcontrol",
    render: (
      <div className="project-item">
        <div className="project-item__title">MonitorControl</div>
        <div className="project-item__desc">
          Control your external monitor brightness & volume on your Mac.
        </div>
        <div className="project-item__icon">
          <FontAwesomeIcon icon={faSwift} title="Swift" />
          <FontAwesomeIcon icon={faApple} title="Apple" />
        </div>
        <div className="project-item__links">
          <a
            className="hover-link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/MonitorControl/MonitorControl"
          >
            Visit
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "yt-pip",
    render: (
      <div className="project-item">
        <div className="project-item__title">youtube-pip-button</div>
        <div className="project-item__desc">
          A small chrome extension that replaces the YouTube miniplayer button
          with the Picture-in-Picture button.
        </div>
        <div className="project-item__icon">
          <FontAwesomeIcon icon={faJsSquare} title="Javascript" />
          <FontAwesomeIcon icon={faChrome} title="Google Chrome" />
        </div>
        <div className="project-item__links">
          <a
            className="hover-link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/JoniVR/youtube-pip-button"
          >
            Visit
          </a>
        </div>
      </div>
    ),
  },
  {
    name: "java",
    render: (
      <div className="project-item">
        <div className="project-item__title">JavaVersionSwitcher</div>
        <div className="project-item__desc">
          A script for easily switching which Java version your system should be
          using on macOS.
        </div>
        <div className="project-item__icon">
          <FontAwesomeIcon icon={faDollarSign} title="Bash" />
        </div>
        <div className="project-item__links">
          <a
            className="hover-link-primary"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/JoniVR/JavaVersionSwitcher"
          >
            Visit
          </a>
        </div>
      </div>
    ),
  },
];

export default ProjectItems;
