import Typist from "react-typist";
import ScrollNext from "../../components/ScrollNext/ScrollNext";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import "./style.scss";

const LandingPage = () => (
  <div className="layout-card" id="home">
    <div className="home">
      <Typist
        className="intro"
        cursor={{ blink: true, hideWhenDone: true, hideWhenDoneDelay: 300 }}
      >
        Hi, I'm Joni!
      </Typist>
      <div className="tagline">
        Software Engineer <br />
        Open Source Enthusiast <br />
        Details Matter
      </div>
      <SocialIcons />
    </div>
    <ScrollNext pageSelector="#about" />
  </div>
);

export default LandingPage;
