import React from 'react';
import ProjectItem from '../../components/ProjectItem/ProjectItem';

import ProjectItems from './ProjectItems';

import './style.scss';

const ProjectPage = () => {
  return (
    <div className="layout-card" id="projects">
      <div className="projects">
        <div className="content-grid">
          <h1>Projects</h1>
          <div className="projects-wrapper">
            {ProjectItems.map((item: any, index: number) => (
              <ProjectItem render={item.render} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
