import './style.scss';

interface ProjectItemProps {
  render: any;
}

const ProjectItem = (props: ProjectItemProps) => {
  return props.render;
};

export default ProjectItem;
