import { useEffect, useState } from 'react';

const useDarkModeListener = () => {
  const [isDark, setIsDark] = useState<boolean>(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', updateIsDark);

    return () => {
      window.removeEventListener('change', updateIsDark);
    };
  });

  const updateIsDark = () => {
    setIsDark(window.matchMedia('(prefers-color-scheme: dark)').matches);
  };

  return isDark;
};

export default useDarkModeListener;
