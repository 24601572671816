import { useContext } from 'react';

import { ThemeContext } from '../providers/ThemeProvider';
import {
  getNextTheme,
  readautoAbsoluteTheme,
  storeTheme,
} from '../utils/ThemeUtils';

const useTheme = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const isDark = (): boolean => {
    if (theme === 'auto') {
      return readautoAbsoluteTheme() === 'dark';
    }
    return theme === 'dark';
  };

  const switchTheme = () => {
    const newTheme = getNextTheme(theme);
    setTheme(newTheme);
    storeTheme(newTheme);
  };

  return { theme, setTheme, isDark, switchTheme };
};

export default useTheme;
