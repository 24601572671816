import React, { useMemo, useState } from 'react';

import { Theme } from '../model/Theme';
import { readTheme } from '../utils/ThemeUtils';
import useDarkModeListener from '../hooks/useDarkModeListener';
import { AbsoluteTheme } from '../model/AbsoluteTheme';

interface ThemeContextType {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

const themeContextDefaultState: ThemeContextType = {
  theme: 'auto',
  setTheme: () => {},
};

export const ThemeContext = React.createContext<ThemeContextType>(
  themeContextDefaultState
);

interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[];
}

const ThemeProvider = (props: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(readTheme());

  const isSystemDark = useDarkModeListener();

  const getThemeClass = useMemo((): AbsoluteTheme => {
    if (theme === 'auto') {
      return isSystemDark ? 'dark' : 'light';
    } else {
      return theme as AbsoluteTheme;
    }
  }, [theme, isSystemDark]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div id="main" className={`theme--${getThemeClass}`}>
        {props.children}
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
