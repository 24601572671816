import Particles from "react-particles-js";
import useTheme from "../../hooks/useTheme";
import "./style.scss";

const ParticlesBackground = () => {
  const { isDark } = useTheme();

  return (
    <div id="particles">
      <Particles
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 1600,
              },
            },
            color: {
              value: isDark() ? "#f5f4f3" : "#272b38",
            },
            move: {
              speed: 6,
            },
            shape: {
              type: "polygon",
              polygon: {
                nb_sides: 4,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: isDark() ? "#f5f4f3" : "#272b38",
              opacity: 0.4,
              width: 1,
            },
          },
        }}
      />
    </div>
  );
};

export default ParticlesBackground;
